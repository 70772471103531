import classes from './SuccessMessage.module.css';

const SuccessMessage = ()=>{
    return (
        <div className={classes.success}>
            Message was sent successfully <span className={classes.icon}>&#9745;</span>
        </div>
    )
}

export default SuccessMessage;