import {withRouter} from 'react-router-dom'
import classes from './Button.module.css';

const Button = (props)=>{

    const changePage =(e)=>{
        console.log(props)
        e.preventDefault()
        if(props.type === 'about'){
            props.history.push('/about')
        }
        if(props.type === 'service'){
            props.history.push('/services')
        }

        if(props.type === 'project'){
            props.history.push('/projects')
        }
        if(props.type === 'contact'){
            props.history.push('/contact')
        }
        

    }

    return(
        <button onClick={(e)=>changePage(e)} className={classes.Button}>
            {props.children}
        </button>
    )

}

export default withRouter(Button);