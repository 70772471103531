import {Link} from 'react-router-dom'

import classes from './Navigation.module.css'
import Logo from '../Logo/Logo'

const Navigation = ()=>{
    return (
        <nav className={classes.Navigation}>
             <Logo/> 
            <ul className={classes.menu_lists}>
                <li><Link to='/about'>About us</Link></li>
                <li><Link to='/services'>Services</Link></li>
                <li><Link to='/projects'>Projects</Link></li>
                <li><Link to='/gallery'>Gallery</Link></li>
                <li><Link to='/contact'>Contacts</Link></li>
            </ul>
        </nav>
    )
}

export default Navigation;