import {useState, useEffect} from 'react'

import Header from '../../components/Header/Header';
import Navigation from '../../components/Navigation/Navigation';
import MobileNav from '../../components/MobileNav/MobileNav';
import Label from '../../components/Label/Label';
import Footer from '../../components/Footer/Footer';
import ContactForm from '../../components/ContactForm/ContactForm';
import SuccessMessage from '../../components/SuccessMessage/SuccessMessage';
import FailedMessage from '../../components/FailedMessage/FailedMessage';
import classes from './ContactPage.module.css';

const ContactPage = ()=>{

    useEffect(()=>{
        return ()=> window.scrollTo(0, 0)
    })

    const [successMsg, setSuccessMsg] = useState(false)
    const [failedMsg, setFailedMsg] = useState(false)

    let confirmationMsg;

    if(successMsg){
        confirmationMsg = <SuccessMessage/>
    }
    else if(failedMsg){
        confirmationMsg = <FailedMessage/>
    }
    else{confirmationMsg = null}

    const confirmMsgHandler = (value)=>{
        console.log(value)
        if(value === 'success'){
            setSuccessMsg(true)
            setTimeout(()=>{
                setSuccessMsg(false)
            }, 3000)
        }

        if(value === 'failed'){
            setFailedMsg(true)
            setTimeout(()=>{
                setFailedMsg(false)
            }, 3000)
        }

    }

    return (
        <>
            
            <MobileNav/>
            <Navigation/>
            {confirmationMsg}
            <Header type= 'contactHeader'>
                
            </Header>
            <Label tag='Contact us'/>
            <main className={classes.main}>

                <ContactForm confirmMessage={confirmMsgHandler}/>

                <section className={classes.first}>
                    <div className={classes.email}>
                        <h2 className={classes.contactHeading}>Email</h2>
                        <p>lujointegratedservices@ymail.com</p>
                        <p>jonasajoku@lujoltd.com</p>
                    </div>

                    <div className={classes.customerCare}>
                        <h2 className={classes.contactHeading}>phone number</h2>
                        <p>+234 (0)8037552556</p>
                        <p>+234 (0)8039397085</p>
                    </div>
                </section>
                

                <div className={classes.phy_address} >
                    <h2 className={classes.contactHeading}>Physical address</h2>
                    <p> 
                        10B, NYECHE STREET, BEHIND SARAH HOUSE, NTA ROAD MGBOUBA, <br/>
                        PORT HARCOURT, RIVERS STATE, NIGERIA. 
                    </p>
                </div>

                <div>
                    <h1 className={classes.mapHeading}>Map Location</h1>
                    <div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3975.511401709856!2d6.971986814446872!3d4.853600041665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1069ce28a26afdc7%3A0x592c2be161260eab!2sLUJO%20INTEGRATED%20SERVICES%20LTD!5e0!3m2!1sen!2sng!4v1635013931331!5m2!1sen!2sng" width="100%" height="600" style={{border:0}} allowFullScreen="" loading="lazy" title='map'></iframe>
                    </div>
                </div>
            </main>

            <Footer/>
        </>
    )
}

export default ContactPage;