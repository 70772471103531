import { useState, useEffect, useRef, useMemo } from "react";

import classes from './Testimonies.module.css';


const Testimonies = ()=>{

    let quoteInterval = useRef();
    let quoteTimeout = useRef();
    const quotes = useRef()
    const quotees = useRef()
    const quoteCount = useRef(0)
    

    const paraQuote = useMemo( ()=>[

        {
            quote: `I usually don't trust a lot of companies with this kind of job, but I make an exception for Lujo Integrated services. They did a very good job.`,
    
            quotee: `Adebayo – SPDC`
        },
    
    
        {
            quote: `Lujo Integrated services are the best. Not only did they deliver my work on time, they did the work neatly`,
    
            quotee: `Abiodun – NDPR`
        }
        
    ], [])

    const [quoteText, setQuoteText] = useState(paraQuote[quoteCount.current])
    

    useEffect(()=>{
        quoteInterval.current = setInterval(()=>{
            if(quoteCount.current === paraQuote.length){
                quoteCount.current = 0
            }
    
            setQuoteText(paraQuote[quoteCount.current]);
            quoteAnimation()

            
            quoteCount.current++
    
        }, 7000)

        return ()=>clearInterval(quoteInterval.current)
        
    }, [quoteCount, quoteInterval, paraQuote])

    useEffect(()=>{
        return ()=>clearTimeout(quoteTimeout.current)
    })

 
    function quoteAnimation(){
        quotes.current.classList.add(classes.anim)
        quotees.current.classList.add(classes.anim)
   
       quoteTimeout.current = setTimeout(() => {
           quotes.current.classList.remove(classes.anim)
           quotees.current.classList.remove(classes.anim)
           
       }, 3500);
   }



    return(
        <section className={classes.Testimonies}>
            <h1>Our Clients <br/> love our work</h1>
            <p ref={quotes} className={classes.anim}>&ldquo;{quoteText.quote}&rdquo;</p>
            <p ref={quotees} className={classes.anim}>&ldquo;{quoteText.quotee}&rdquo;</p>
        </section>
    )
}

export default Testimonies;