import {Link} from 'react-router-dom'
import classes from './Logo.module.css';
import logoImg from '../../media/lujo-logo.png'

const Logo = ()=>{
    return (
        <div className={classes.Logo}>
            <Link to='/'><img className={classes.logoImg} src={logoImg} alt='logo'/></Link>
        </div>
    )
}

export default Logo;