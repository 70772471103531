import {Link} from 'react-router-dom'
import Logo from "../Logo/Logo"
import classes from './Footer.module.css';

const Footer = ()=>{
    return (
        <footer className={classes.Footer}>
            <section>
                <Logo/>
            </section>
            <section>
                <h2>Menu</h2>
                <ul className={classes.menu}>
                    <li><Link to='/about'>ABOUT US</Link></li>
                    <li><Link to='/services'>SERVICES</Link></li>
                    <li><Link to='/projects'>PROJECTS</Link></li>
                    <li><Link to='/gallery'>GALLERY</Link></li>
                    <li><Link to='/contact'>CONTACTS</Link></li>

                </ul>
            </section>

            <section>
                <h2>Address</h2>
                <p>
                    Lujo Integrated Services Limited <br/>
                    10B Nyeche street, <br/> 
                    behind Sarah House, <br/>
                    NTA road, Mgbouba, <br/>
                    Port Hacourt, Rivers state, <br/>
                    Nigeria.
                </p>

            </section>

            <section>
            <h2>Contacts</h2>
                <ul className={classes.contactList}>
                    <li> +234 (0)8037552556</li>
                    <li> +234 (0)8039397085</li>
                </ul>
            </section>
        </footer>
    )
}

export default Footer;