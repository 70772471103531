import classes from './FailedMessage.module.css';

const FailedMessage = ()=>{
    return (
        <div className={classes.failed}>
            This message failed to send, check network <span className={classes.icon}>&#9746;</span>
        </div>
    )
}

export default FailedMessage;