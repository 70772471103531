import { useEffect } from 'react';
import Header from '../../components/Header/Header';
import Navigation from '../../components/Navigation/Navigation';
import MobileNav from '../../components/MobileNav/MobileNav';
import Label from '../../components/Label/Label';
import Footer from '../../components/Footer/Footer';
import classes from './ProjectsPage.module.css';

const ProjectsPage = ()=>{
    useEffect(()=>{
        return ()=> window.scrollTo(0, 0)
     })
    return (
        <>
            <MobileNav/>
            <Navigation/>
            <Header type= 'projectHeader'>
                
            </Header>
            <Label tag='Projects'/>

            <main className={classes.main}>
                <table className={classes.table}>
                    <tbody>
                        <tr>
                            <th className={classes.rowHeading}>S/N</th>
                            <th className={classes.rowHeading}>DESCRIPTION</th>
                            <th className={classes.rowHeading}>CONTRACT/PO NO</th>
                            <th className={classes.rowHeading}>CLIENT NAME</th>
                            <th className={classes.rowHeading}>YEAR</th>
                            <th className={classes.rowHeading}>STATUS</th>
                            
                        </tr>

                        <tr>
                            <td className={`${classes.rowData} ${classes.sn}`}>1</td>
                            <td className={`${classes.rowData} ${classes.des}`}>CATHODIC PROTECTION FOR 18'X65KM UQUO-IKOT ABASI GAS PIPE</td>
                            <td className={`${classes.rowData} ${classes.contract}`}>P11001 </td>
                            <td className={`${classes.rowData} ${classes.client}`}>DAAC/SE PTA ENERGY</td>
                            <td className={`${classes.rowData} ${classes.year}`}>2011 </td>
                            <td className={`${classes.rowData} ${classes.status}`}>EXECUTED</td>
                           
                        </tr>

                        <tr>
                            <td className={`${classes.rowData} ${classes.sn}`}>2</td>
                            <td className={`${classes.rowData} ${classes.des}`}>
                                COMPLETE POLARIZATION OF 
                                CATHODIC PROTECTION (ON/OFF) 
                                POTENTIAL SURVEY OF NDPR 
                                PIPELINE NETWORK. <br/>
                                12” X 20KM OGELE – RUMUJI <br/>
                                6” X 7KM OGBELE – RUMUEKPE <br/>
                                4” X 3.5KM F/S – WELL 1 <br/>
                                4” X 2.1KM F/S – WELL 3 <br/>
                                6” X 5KM GAS PLANT -WELLS 1 & 6
                            </td>
                            <td className={`${classes.rowData} ${classes.contract}`}>FO/256</td>
                            <td className={`${classes.rowData} ${classes.client}`}>NDPR</td>
                            
                            <td className={`${classes.rowData} ${classes.year}`}>2012</td>
                            <td className={`${classes.rowData} ${classes.status}`}>EXECUTED</td>
                            
                        </tr>

                        <tr>
                            <td className={`${classes.rowData} ${classes.sn}`}>3</td>
                            <td className={`${classes.rowData} ${classes.des}`}>
                                POTENTIAL INTERFERENCE
                                INVESTIGATION BETWEEN 12'' NDPR 
                                PIPELIN AND 28'' NLNG PIPELINE AT 
                                SOKU RUMUJI NODE, PROCURE AND 
                                INSTALLATION OF EQUIPOTENTIAL 
                                TEST POST UNITS.
                            </td>
                            <td className={`${classes.rowData} ${classes.contract}`}>NDPR-13-031</td>
                            <td className={`${classes.rowData} ${classes.client}`}>NDPR</td>
                            
                            <td className={`${classes.rowData} ${classes.year}`}>2013 </td>
                            <td className={`${classes.rowData} ${classes.status}`}>EXECUTED</td>
                            
                        </tr>

                        <tr>
                            <td className={`${classes.rowData} ${classes.sn}`}>4</td>
                            <td className={`${classes.rowData} ${classes.des}`}>
                            COMPLETE POLARIZATION OF 
                                CATHODIC PROTECTION (ON/OFF) 
                                POTENTIAL SURVEY OF NDPR 
                                PIPELINE NETWORK. <br/>
                                12” X 20KM OGELE – RUMUJI <br/>
                                6” X 7KM OGBELE – RUMUEKPE <br/>
                                4” X 3.5KM F/S – WELL 1 <br/>
                                4” X 2.1KM F/S – WELL 3 <br/>
                                6” X 5KM GAS PLANT -WELLS 1 & 6
                            </td>
                            <td className={`${classes.rowData} ${classes.contract}`}>FO/289</td>
                            <td className={`${classes.rowData} ${classes.client}`}>NDPR</td>
                            
                            <td className={`${classes.rowData} ${classes.year}`}>2014</td>
                            <td className={`${classes.rowData} ${classes.status}`}>EXECUTED</td>
                            
                        </tr>

                        <tr>
                            <td className={`${classes.rowData} ${classes.sn}`}>5</td>
                            <td className={`${classes.rowData} ${classes.des}`}>
                            INTERGRITY VERIFICATION FOR 
                            SPDC PIPELINES (LAND AND SWAMP)

                            </td>
                            <td className={`${classes.rowData} ${classes.contract}`}>4510316890 </td>
                            <td className={`${classes.rowData} ${classes.client}`}>SPDC</td>
                           
                            <td className={`${classes.rowData} ${classes.year}`}>2014</td>
                            <td className={`${classes.rowData} ${classes.status}`}>EXECUTED</td>
                            
                        </tr>

                        <tr>
                            <td className={`${classes.rowData} ${classes.sn}`}>6</td>
                            <td className={`${classes.rowData} ${classes.des}`}>
                                SHELL PETROLEUM DEVELOPMENT COMPANY (INTEGRITY VERFICATION, 
                                UT & CP) AT YOKRI
                            </td>
                            <td className={`${classes.rowData} ${classes.contract}`}>4510391792</td>
                            <td className={`${classes.rowData} ${classes.client}`}>SPDC</td>
  
                            <td className={`${classes.rowData} ${classes.year}`}>2017</td>
                            <td className={`${classes.rowData} ${classes.status}`}>EXECUTED</td>
                            
                        </tr>

                        <tr>
                            <td className={`${classes.rowData} ${classes.sn}`}>7</td>
                            <td className={`${classes.rowData} ${classes.des}`}>
                                INSTALLATION OF EARTHING AND 
                                LIGHTNINIG PROTECTION SYSTEM 
                                KRAKAMA F/S
                            </td>
                            <td className={`${classes.rowData} ${classes.contract}`}>EEP-PR-1562</td>
                            <td className={`${classes.rowData} ${classes.client}`}>EROTON</td>
                            
                            <td className={`${classes.rowData} ${classes.year}`}>2017</td>
                            <td className={`${classes.rowData} ${classes.status}`}>EXECUTED</td>
                            
                        </tr>

                        <tr>
                            <td className={`${classes.rowData} ${classes.sn}`}>8</td>
                            <td className={`${classes.rowData} ${classes.des}`}>
                                THE ANNUAL POLARIZATION 
                                CATHODIC PROTECTION POTENTIAL 
                                SURVEY OF NDPR’S PIPELINE 
                                NETWORK.
                            </td>
                            <td className={`${classes.rowData} ${classes.contract}`}>4500002360</td>
                            <td className={`${classes.rowData} ${classes.client}`}>NDPR</td>
                            
                            <td className={`${classes.rowData} ${classes.year}`}>2017</td>
                            <td className={`${classes.rowData} ${classes.status}`}>EXECUTED</td>
                            
                        </tr>

                        <tr>
                            <td className={`${classes.rowData} ${classes.sn}`}>9</td>
                            <td className={`${classes.rowData} ${classes.des}`}>
                            POLARIZED CATHODIC PROTECTION POTENTIAL
                            </td>
                            <td className={`${classes.rowData} ${classes.contract}`}>4500003304</td>
                            <td className={`${classes.rowData} ${classes.client}`}>NDPR</td>
                            
                            <td className={`${classes.rowData} ${classes.year}`}>2018</td>
                            <td className={`${classes.rowData} ${classes.status}`}>EXECUTED</td>
                            
                        </tr>

                        <tr>
                            <td className={`${classes.rowData} ${classes.sn}`}>10</td>
                            <td className={`${classes.rowData} ${classes.des}`}>
                                PROCUREMENT AND INSTALLATION 
                                OF CATHODIC PROTECTION BULK 
                                MATERIAL AT TUNU.
                            </td>
                            <td className={`${classes.rowData} ${classes.contract}`}>
                                SAIDEL- <br/> LA/18/CTC/ <br/> 00086
                            </td>
                            <td className={`${classes.rowData} ${classes.client}`}>DESICON</td>
                            
                            <td className={`${classes.rowData} ${classes.year}`}>2018</td>
                            <td className={`${classes.rowData} ${classes.status}`}>EXECUTED</td>
                            
                        </tr>

                        <tr>
                            <td className={`${classes.rowData} ${classes.sn}`}>11</td>
                            <td className={`${classes.rowData} ${classes.des}`}>
                                PURCHASE ORDER FOR CATHODIC 
                                PROTECTION SURVEY AND TESTPOST 
                                REPAIRS.
                            </td>
                            <td className={`${classes.rowData} ${classes.contract}`}>4500005026</td>
                            <td className={`${classes.rowData} ${classes.client}`}>NDPR</td>
                            
                            <td className={`${classes.rowData} ${classes.year}`}>2019</td>
                            <td className={`${classes.rowData} ${classes.status}`}>EXECUTED</td>
                            
                        </tr>

                        

                        <tr>
                            <td className={`${classes.rowData} ${classes.sn}`}>12</td>
                            <td className={`${classes.rowData} ${classes.des}`}>
                                CATHODIC PROTECTION WORK AT 
                                AGBARA
                            </td>
                            <td className={`${classes.rowData} ${classes.contract}`}>4800000862</td>
                            <td className={`${classes.rowData} ${classes.client}`}>
                                SEANUT & <br/>
                                GREENVILLE
                            </td>
                            
                            <td className={`${classes.rowData} ${classes.year}`}>2020</td>
                            <td className={`${classes.rowData} ${classes.status}`}>EXECUTED</td>
                            
                        </tr>

                        <tr>
                            <td className={`${classes.rowData} ${classes.sn}`}>13</td>
                            <td className={`${classes.rowData} ${classes.des}`}>CPF CP LPS RESTORATION</td>
                            <td className={`${classes.rowData} ${classes.contract}`}>
                                SO-1021-<br/>10201 v1
                            </td>
                            <td className={`${classes.rowData} ${classes.client}`}>
                                SAVANNAH ENERGY
                            </td>
                            
                            <td className={`${classes.rowData} ${classes.year}`}>2020</td>
                            <td className={`${classes.rowData} ${classes.status}`}>EXECUTED</td>
                            
                        </tr>

                        <tr>
                            <td className={`${classes.rowData} ${classes.sn}`}>14</td>
                            <td className={`${classes.rowData} ${classes.des}`}>EARTHING AND LIGHTENING PROTECTION</td>
                            <td className={`${classes.rowData} ${classes.contract}`}>100003624</td>
                            <td className={`${classes.rowData} ${classes.client}`}>SAVANNAH ENERGY</td>
                            
                            <td className={`${classes.rowData} ${classes.year}`}>2020</td>
                            <td className={`${classes.rowData} ${classes.status}`}>EXECUTED</td>
                            
                        </tr>

                    

                        <tr>
                            <td className={`${classes.rowData} ${classes.sn}`}>15</td>
                            <td className={`${classes.rowData} ${classes.des}`}>
                                CATHODIC PROTECTION SYSTEM 
                                INSTALLATIONS. GBU PH3A
                            </td>
                            <td className={`${classes.rowData} ${classes.contract}`}>
                                SA_OPAM1-
                                KEM01P_01
                            </td>
                            <td className={`${classes.rowData} ${classes.client}`}>DAEWOO</td>
                            
                            <td className={`${classes.rowData} ${classes.year}`}>2020</td>
                            <td className={`${classes.rowData} ${classes.status}`}>Ongoing</td>
                            
                        </tr>
                    </tbody>

                </table>
            </main>

            <Footer/>
        </>
        
    )
}

export default ProjectsPage;

