import { useState, useEffect, useRef, useMemo} from 'react';

import classes from './HomePage.module.css';
import Navigation from '../../components/Navigation/Navigation';
import MobileNav from '../../components/MobileNav/MobileNav';
import SuccessMessage from '../../components/SuccessMessage/SuccessMessage';
import FailedMessage from '../../components/FailedMessage/FailedMessage';
import Button from '../../components/Button/Button';
import Pi from '../../media/pi.jpg';
import Pi2 from '../../media/pi-2.jpg';
import Pi3 from '../../media/pi-3.jpg';
import pic1 from '../../media/cathodicPro.jpg';
import pic2 from '../../media/homePageBgPic-3.jpg';
import pic3 from '../../media/galleryPic.jpg';
import pic4 from '../../media/projectPic.jpg';
import savanah from '../../media/savanah.png';
import eroton from '../../media/eroton.png';
import desicon from '../../media/desicon.png';
import npdr from '../../media/npdr.png';
import shell from '../../media/shell.png';
import Testimonies from '../../components/Testimonies/Testimonies';
import Footer from '../../components/Footer/Footer';
import ContactForm from '../../components/ContactForm/ContactForm';

const HomePage = ()=>{
    
    const x = window.matchMedia("(max-width: 768px)")
    const [bgImg, setBgImg] = useState(x.matches ? classes.miniHomeBgImg1 : classes.homeBgImg1)
    const [successMsg, setSuccessMsg] = useState(false)
    const [failedMsg, setFailedMsg] = useState(false)
  
    let counter = useRef(0)
    const heading = useRef();
    const details = useRef();
    let bgChanger = useRef();
    let scribbleChanger = useRef();
    
    
    
    
    const headerInfo = useMemo(()=>[
        {
            heading: 'Lujo',
            info: `When it comes to construction and maintenance of pipelines, we are the best.
                   We focus on quality and safety while using the state of the art materials and techniques
                   to deliver the best possible service to our clients
                    `, 
            img: classes.homeBgImg1,
             imgMini: Pi
        }, 
        {
            heading: 'Quality',
            info: `At Lujo, quality is one of our main focus. Our experienced staffs utilize
                   Advanced analysis to determine the most suitable materials and technique
                   for the job,`,        
            img: classes.homeBgImg2,
            imgMini: Pi2
        }, 
        {
            heading: 'Safety',
            info: `Safty is another of our core values here at Lujo, we put the saftey of our clients
                   and our staffs first while undertaking any project `,
            img: classes.homeBgImg3,
            imgMini: Pi3
        }
    ], [])

     

    useEffect(()=>{
            
        bgChanger.current = setInterval(()=>{

            counter.current += 1
            
            if(counter.current > headerInfo.length -1){
                
                counter.current = 0
            }
            
            if(x.matches){
                setBgImg(headerInfo[counter.current].imgMini)
            }
            else{ setBgImg(headerInfo[counter.current].img)}
            
            changeText()
              
                       
        }, 7000) 

        return ()=>clearInterval(bgChanger.current)
        
        
    }, [counter, headerInfo, x.matches])

    useEffect(()=>{
        const rights = document.querySelectorAll('.'+classes.right)
        const lefts = document.querySelectorAll('.'+classes.left)
        const scribbleVar = scribbleChanger.current
        

        lefts.forEach(left => {
            obsever.observe(left)
            
        });

        rights.forEach(right => {
            obsever.observe(right)
            
        });

        return ()=> clearTimeout(scribbleVar)
          
    })

    useEffect(()=>{
        return ()=> window.scrollTo(0, 0)
    }, [])

    const changeText = ()=>{
        heading.current.classList.add(classes.heading)
        details.current.classList.add(classes.details)

         scribbleChanger.current = setTimeout(() => {
            heading.current.classList.remove(classes.heading)
            details.current.classList.remove(classes.details)    
        }, 1000);

    }

    const slide = (entries)=>{
        entries.forEach(entry=>{
            
            if(!entry.isIntersecting){
                return
                
            }
            else{
                if(entry.target.className.includes('right')){
                    entry.target.classList.add(classes.rightAnim)
                }

                entry.target.classList.add(classes.leftAnim)
            }
        })
        
        
    }


    const obsever = new IntersectionObserver(slide, {
        threshold: 0.3
    })

    let confirmationMsg;

    if(successMsg){
        confirmationMsg = <SuccessMessage/>
    }
    else if(failedMsg){
        confirmationMsg = <FailedMessage/>
    }
    else{confirmationMsg = null}

    const confirmMsgHandler = (value)=>{
        console.log(value)
        if(value === 'success'){
            setSuccessMsg(true)
            setTimeout(()=>{
                setSuccessMsg(false)
            }, 5000)
        }

        if(value === 'failed'){
            setFailedMsg(true)
            setTimeout(()=>{
                setFailedMsg(false)
            }, 5000)
        }

    }
    
    return (
        <>
            
            <MobileNav/>
            <Navigation/>
            {confirmationMsg}
            <div className={classes.modal}>
            
                <div className={classes.headerInscription}>

                    <h1 className={classes.heading} ref={heading}>{headerInfo[counter.current].heading}</h1>
                    <p className={classes.details} ref={details}>{headerInfo[counter.current].info}</p>
                    
                </div>

            </div>
            <header className={`${classes.homeHeader} ${x.matches ? null : bgImg}`}>
                {x.matches ? <img className={classes.headerImg} src={headerInfo[counter.current].imgMini} alt='pipeline workers'/> : null}
            </header>

            <main className={classes.Hmain}>
                <section className={classes.section}>

                    <div   className={`${classes.pic} ${classes.left}`}>
                        <img src={pic1} alt='people working on pipes'/>
                    </div>

                    <div className={`${classes.text} ${classes.right}`}>
                        <h1>Who we are</h1>
                        <p>
                            LUJO INTEGRATED SERVICES LIMITED is a leading EPCI, that renders services to different economic 
                            sectors, such as oil and gas, energy companies with its head office in Port Harcourt, Nigeria.
                            
                        </p>
                        <Button type='about'>Learn more</Button>
                    </div>
                </section>

                <section className={classes.section}>
                    
                    <div className={`${classes.text} ${classes.left}`}>
                        <h1>What we do</h1>
                        <p>
                            LUJO's main area of specialization includes the following: Design and Engineering, Procurement & 
                            Construction, maintenance and supply of oil and gas tools, Corrosion industrials Earth/grounding 
                            system, structural integrity.
                        </p>
                        <Button type='service'>Learn more</Button>
                    </div>
                    <div className={`${classes.pic} ${classes.right}`}>
                    <img src={pic2} alt='men working on pipes'/>
                    </div>
                </section>

                <section className={classes.section}>
                    <div className={`${classes.pic} ${classes.left}`}>
                    <img src={pic3} alt='gushing pipes'/>
                    </div>

                    <div className={`${classes.text} ${classes.right}`}>
                        <h1>What we've done</h1>
                        <p>
                            LUJO Integrated services have carried out lots of projects for leading companies
                            over a period of 10 years. we have put smiles on the faces of our clients by delivering
                            quality and satisfactory services
                        </p>
                        <Button type='project'>Learn more</Button>
                    </div>
                </section>

                <section className={classes.section}>
                    
                    <div className={`${classes.text} ${classes.left}`}>
                        <h1>How to reach us</h1>
                        <p>
                            You can contact us through the following means: <br/>
                            Email: lujointegratedservices@ymail.com <br/>
                            telephone: +234 (0)8037552556 <br/> 
                            office: 10B, NYECHE STREET, BEHIND SARAH HOUSE, NTA ROAD MGBOUBA, PORT HARCOURT, RIVERS STATE, NIGERIA
                        </p>
                        <Button type='contact'>Learn more</Button>
                    </div>

                    <div className={`${classes.pic} ${classes.right}`}>
                        <img src={pic4} alt='heavy duty vehicle'/>
                    </div>
                </section>

                <Testimonies/>

                <section className={classes.clientLogoSection} >
                    <h1>Our Clients</h1>
                    <div className={classes.clientLogo}>

                        <figure className={classes.logoBox}>
                            <img className={classes.logo} src={npdr} alt='npdr logo'/>
                        </figure>
                        <figure className={classes.logoBox}>
                            <img className={classes.logo} src={savanah} alt='savanah logo'/>
                        </figure>
                      
                    
                        <figure className={`${classes.logoBox} ${classes.eroton} `}>
                            <img className={classes.logo} src={eroton} alt='eroton logo'/>
                        </figure>
                        <figure className={classes.logoBox}>
                            <img className={classes.logo} src={desicon} alt='desicon logo'/>
                        </figure>
                   
                       
                        <figure className={classes.logoBox}>
                            <img className={classes.logo} src={shell} alt='shell logo'/>
                        </figure>
                      
                    </div> 
                </section>

                <ContactForm confirmMessage={confirmMsgHandler}/>
                     
            </main>

            <Footer/>
        </>   
        
    )
}

export default HomePage;