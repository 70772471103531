import {useState} from 'react';
import emailjs from 'emailjs-com'; 
import classes from './ContactForm.module.css';


const ContactForm = (props)=>{
    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNum, setPhoneNum] = useState('')
    const [message, setMessage] = useState('')
    

    const inputHandler = (e, field)=>{
        if(field === 'fullName'){
            setFullName(e.target.value) 
        }

        if(field === 'email'){
            setEmail(e.target.value) 
        }

        if(field === 'phoneNum'){
            setPhoneNum(e.target.value) 
        }

        if(field === 'message'){
            setMessage(e.target.value) 
        }

    }

    const sendEmailHandler = (e)=>{
        e.preventDefault()

        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, e.target, process.env.REACT_APP_USER_ID)
        .then(res=>{
            props.confirmMessage('success')
        })
        .catch(err=>{
            props.confirmMessage('failed')
        })
    }

    

    
    return (
        
        <section className={classes.ContactForm}>
            <h1>WRITE US A MESSAGE</h1>
            <form className={classes.form} onSubmit={sendEmailHandler}>

                <div className={classes.inputContainer}>
                    <label>Full name</label>
                    <input name='fullName' onChange={(e)=>inputHandler(e, 'fullName')} value={fullName} placeholder='enter your first and last name' className={classes.input} />
                </div>   
                
                <div className={classes.inputContainer}>
                    <label>email address</label>
                    <input type='email' name='user_email' onChange={(e)=>inputHandler(e, 'email')} value={email} placeholder='enter your email adress' className={classes.input}/>
                </div>
                
                
                <div className={classes.inputContainer}>
                    <label>Phone number</label>
                    <input name='phoneNum' onChange={(e)=>inputHandler(e, 'phoneNum')} value={phoneNum} placeholder='enter your phone number' className={classes.input}/>
                </div>        
                
                
                <div className={classes.inputContainer}>
                    <label>Your message</label>
                    <textarea name='message' onChange={(e)=>inputHandler(e, 'message')} value={message} placeholder='type your message' className={classes.message}></textarea>
                </div>

                <div className={classes.btnContainer}><input className={classes.formBtn} value={'Submit'} type='submit' /></div>
                
        
            </form>
        </section>
        
    )
}

export default ContactForm;