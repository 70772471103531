import { Route } from 'react-router';

import './App.module.css';
import HomePage from "./containers/HomePage/HomePage";
import AboutPage from "./containers/AboutPage/AboutPage";
import ContactPage from "./containers/ContactPage/ContactPage";
import GalleryPage from "./containers/GalleryPage/GalleryPage";
import ProjectsPage from "./containers/ProjectsPage/ProjectsPage";
import ServicesPage from "./containers/ServicesPage/ServicesPage";

function App() {
  return (
    <div className="App">
      <Route path='/' exact component={HomePage}/>
      <Route path='/about' component={AboutPage}/>
      <Route path='/contact' component={ContactPage}/>
      <Route path='/gallery' component={GalleryPage}/>
      <Route path='/projects' component={ProjectsPage}/>
      <Route path='/services' component={ServicesPage}/>
    </div>
  );
}

export default App;
