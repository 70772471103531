import { Link } from 'react-router-dom';
import classes from './MobileNav.module.css';
import Logo from '../Logo/Logo';

const MobileNav = ()=>{
    return(
        <div className={classes.navigation}>
            <div className={classes.logoDiv}>
                <Logo/>
            </div>
            
            <input type="checkbox" className={classes.navigation__checkbox} id="navi-toggle"/>

            <label htmlFor ="navi-toggle" className={classes.navigation__button}>
                <span className={classes.navigation__icon}>&nbsp;</span>
            </label>

            <div className={classes.navigation__background}>&nbsp;</div>

            <nav className={classes.navigation__nav}>
                <ul className={classes.navigation__list}>
                    <li className={classes.navigation__item}><Link className={classes.navigation__link} to='/'>HOME</Link></li>
                    <li className={classes.navigation__item}><Link className={classes.navigation__link} to='/about'>ABOUT US</Link></li>
                    <li className={classes.navigation__item}><Link className={classes.navigation__link} to='/services'>SERVICES</Link></li>
                    <li className={classes.navigation__item}><Link className={classes.navigation__link} to='/projects'>PROJECTS</Link></li>
                    <li className={classes.navigation__item}><Link className={classes.navigation__link} to='/gallery'>GALLERY</Link></li>
                    <li className={classes.navigation__item}><Link className={classes.navigation__link} to='/contact'>CONTACT</Link></li>
                </ul>
            </nav>
        </div>
    )
}

export default MobileNav;