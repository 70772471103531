import Header from '../../components/Header/Header';
import Navigation from '../../components/Navigation/Navigation';
import MobileNav from '../../components/MobileNav/MobileNav';
import Label from '../../components/Label/Label';
import Footer from '../../components/Footer/Footer';
import classes from './AboutPage.module.css';
import { useEffect } from 'react';

const AboutPage = ()=>{

    useEffect(()=>{
       return ()=> window.scrollTo(0, 0)
    })
    return (
        <>
            <MobileNav/>
            <Navigation/>
            <Header type= 'aboutHeader'>     
            </Header>
            <Label tag='About us'/>
            <main className={classes.main}>
                <h2 className={classes.heading}>Introduction</h2>
                <p className={classes.para}>
                    LUJO INTEGRATED SERVICES LIMITED is a leading EPCI, that renders services to different economic 
                    sectors, such as oil and gas, energy companies with its head office in Port Harcourt, Nigeria.
                    Its main area of specialization includes the following: Design and Engineering, Procurement & 
                    Construction, maintenance and supply of oil and gas tools, Corrosion industrials Earth/grounding 
                    system, structural integrity.
                    Incorporated in 2008, LUJO has assembled its personnel with experience in all facets of corrosion, 
                    materials, electrical engineering and NDT Services.
                    The company has successfully executed projects for diverse oil and gas companies. This includes but 
                    not limited to SHELL NIGERIA, DAEWOO NIGERIA LTD, SAIPEM CONTRACTING, NIGER DELTA 
                    PETROLEUM RESOURCE.
                </p>
                <p className={classes.para}>
                    With the vast wealth of experience and technical know-how, coupled with our in-depth knowledge of 
                    the local working condition within the last 10 years, our clients are sure of successful execution of 
                    their projects in record time.
                </p>

                <h2 className={classes.heading}>OEM Relationship</h2>
                <p className={classes.para}>
                    Lujo has strong technical partnership with CORRPRO COMPANIES EUROPE LIMITED (CCEL), owners 
                    of HOCKWAY, United Kingdom, a leading name in cathodic protection, and is their sole representative
                    in NIGERIA.
                </p>
                

                <h2 className={classes.heading}>OWNERSHIP STRUCTURE</h2>
                <p className={classes.para}>
                    LUJO INTEGRATED SERVICES LTD is a 100% Nigerian Company.
                    Engr. Jonas.C Ajoku as the Managing Director and Chief Executive Officer 
                </p>

                <h2 className={classes.heading}>SUB CONTRACTING</h2>
                <p className={classes.para}>
                    LUJO INTEGRATED SERVICES LTD will perform all jobs directly using our own workforce. However, 
                    in the event of certain specialized activities, LUJO may consider subcontracting such activities to 
                    qualified subcontractors who will equally maintain the client approved standards. 
                </p>

                

            </main>

            <Footer/>
        </>
    )
}

export default AboutPage;