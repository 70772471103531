import { useEffect } from 'react';
import Header from '../../components/Header/Header';
import Navigation from '../../components/Navigation/Navigation';
import MobileNav from '../../components/MobileNav/MobileNav';
import Label from '../../components/Label/Label';
import Footer from '../../components/Footer/Footer';
import CathodePic1 from '../../media/cathode-pic1.jpg';
import CathodePic2 from '../../media/cathode-pic2.jpg';
import Cathodic from '../../media/cathodic.jpg'
import Cathodic2 from '../../media/cathodic2.jpg';
import CathodicProtection from '../../media/cathodic-protection.jpg';
import classes from './ServicesPage.module.css';

const ServicesPage = ()=>{
    useEffect(()=>{
        return ()=> window.scrollTo(0, 0)
     })
    return (
    <>
        <MobileNav/>
        <Navigation/>
        <Header type= 'serviceHeader'>
            
        </Header>
        <Label tag='Services'/>

        <main className={classes.main}>
                <h2 className={classes.heading}>CORE SPECIALITY</h2>
                <p className={classes.para}>
                    LUJO INTEGRATED SERVICES LIMITED provides the following specialized services: <br/>
                    SUPPLY SERVICES of the following:
                </p>
                <ul className={classes.serviceList}>
                    <li>Engineering Design: Corrosion and Earthing and lightening</li>
                    <li>Cathodic Protection Equipment and Materials</li>
                    <li>Non-Destructive Testing (NDT).</li>
                    <li>Solar panel units, accessories</li>
                    <li>Design and Installation of Earthing and Lightning Protection System</li>
                    <li>Technical Consultation and Expert Services</li>
                </ul>

                <h2 className={classes.heading}>INSTALLATION SERVICES and MAINTENANCE of the following:</h2>
                <ul className={classes.serviceList}>
                    <li>Cathodic Protection System: Construction and installation of equipment’s such as Transformer 
                        rectifier, Anodes [Impress & Sacrificial, Junction boxes, Test post and ground bed 
                        installation (deep well and horizontal)]</li>

                    <li>Non-Destructive Testing such as: Radiography Testing, Ultrasonic Testing, Magnetic Particle 
                        Testing, Penetrants (Dye) Testing, Corrosion Mapping</li>
                    <li>Current Interference and Mitigation</li>
                    <li>Current Mapping</li>
                    <li>Close Interval Potential Surveys (CIPS) and Direct Current Voltage Gradient (DCVG)</li>
                    <li>Corrosion monitoring through metal loss, PH analysis etc.</li>
                    <li>Solar panel installation and repairs.</li>
                    <li>Potential Survey and control of sub-criterion conditions</li>
                    <li>Soil Resistivity</li>
                    <li>Electrical Downhole logging</li>
                </ul>

                <h2 className={classes.heading}>PROCUREMENT/SUPPLIES </h2>
                <p>
                    LUJO INTEGRATED SERVICES LTD having established good and firm relationship with Corprro/Hockway
                    limited of whom are we their Nigerian representative. The company can make foreign purchases for you,
                    especially in the areas of cathodic protection materials and other Electrical and Mechanical Engineering 
                    material with internationally accepted quality within short notices.<br/>
                    Local purchases can also be made at your demand, which will meet your specifications. 
                    We stock these materials including industrial chemicals for oil field services as well as supply skilled and 
                    unskilled manpower.
                </p>

                <h2 className={classes.heading}>LIST OF MATERIALS</h2>
                <ul className={classes.serviceList}>
                    <li>Transformer Rectifier</li>
                    <li>Anodes (Impress and Sacrificial)</li>
                    <li>Junction boxes</li>
                    <li>Test post</li>
                    <li>Ground bed Installation Material</li>
                    <li>Earthing and Lightening Material</li>
                </ul>

                <h2 className={classes.heading}>CATHODIC PROTECTION</h2>
                <p>
                    The need for and methods of application of cathodic protection are usually straight forward. In fact there are 
                    occasions when difficult and unusual applications are considered which are outside the scope of conventional 
                    companies
                </p>
                <figure>
                    <img src={CathodePic1} alt='men working'/>
                    <img src={CathodePic2} alt='men working'/>
                </figure>
                <p className={classes.inscription}>LUJO: Impressed Current Cathodic Protection System Project at SPDC Tunu CPF 2018</p>

                <p>
                    LUJO INTEGRATED SERVICES LTD employs highly experienced engineers and cathodic protection specialists to 
                    resolve and advise on the following matters.
                </p>

                <ul className={classes.serviceList}>
                    <li>PIPELINES</li>
                    <li>TANK FARMS</li>
                    <li>INDUSTRIAL PLANT</li>
                    <li>STRUCTURES AND FACILITIES IN MARINE INDUSTRY</li>
                </ul>

                <h2 className={classes.heading}>CATHODIC PROTECTION INSTALLATION, SUPERVISION AND COMMISSIONING</h2>
                <p>
                    LUJO INTEGRATED SERVICES LTD engineering crew comprises of qualified and experienced engineers and 
                    technicians that install, supervise, test and maintain complete cathodic protection systems for various 
                    industrial structure/facilities. LUJO INTEGRATED SERVICES LTD does not normally require expatriate staff for 
                    most of its Cathodic Protection works except in very special areas.<br/>
                    However, the company in her MOU with Hockway; gets quality Corrosion Control Materials, Expertise & Advice 
                    on all major Projects.
                </p>

                <h2 className={classes.heading}>CATHODIC PROTECTION MAINTENANCE</h2>
                <p>
                    LUJO INTEGRATED SERVICES LTD runs permanent contracts for periodical maintenance of various Cathodic 
                    Protecti Systems especially pipeline networks, tank farms, marine platforms and plants. The monitoring, 
                    fault locations, interference tests, inspection, potential logging, reports and documentation are the normal 
                    maintenance work for LUJO INTEGRATED SERVICES LTD Engineering Team.<br/>
                    A Cathodic Protection System and indeed any other Engineering system can only remain efficient if it is 
                    inspected at regular intervals. This requires the establishment of a suitable maintenance programme for
                    various types of Cathodic systems. Such maintenance services could include provision of well-qualified and 
                    experienced personnel to carry out maintenance work.<br/>
                    For older Cathodic protection system requiring trouble-shooting and reactivation or retrofitting, LUJO provides
                    a complete range of material/Equipment and personnel to reinstate or restore satisfactory/sufficient 
                    protection on the facility of interest.<br/>
                    Lujo Integrated Services Ltd maintenance activities for structures and facilities include but not limited to the 
                    following services:
                </p>

                <ul className={classes.serviceList}>
                    <li>Site survey</li>
                    <li>Re-Design & Engineering</li>
                    <li>Installation</li>
                    <li>Supervision</li>
                    <li>Startup & commissioning</li>
                    <li>Fault Location (Trouble shooting)</li>
                    <li>Maintenance</li>
                    <li>Failure analysis</li>
                    <li>Remote monitoring</li>
                </ul>

                <h2 className={classes.heading}>CATHODIC PROTECTION SITE SURVEY</h2>
                <p>
                    Lujo Integrated Services Ltd has a very competent technical crew that can conduct and interpret the following 
                    test and surveys:
                </p>
                <ol className={classes.serviceList}>
                    <li>PEARSON SURVERY</li>
                    <li>SOIL RESISTIVITY SURVEY</li>
                    <li>POLARISED POTENTIAL SURVEY</li>
                    <li>CURRENT DRAIN TEST</li>
                    <li>INTERFERENCE AND STRAY CURRENT TESTS</li>
                </ol>

                <p>
                    In addition to the soil resistivity survey by AC meter for shallow horizontal or vertical groundbeds, LUJO 
                    conducts deeper soil resistivity testing for the purpose of deep well groundbed locations.
                </p>

                <figure className={classes.fig}>
                    <img className={classes.servicePic} src={Cathodic} alt='men working'/>
                </figure>

                <p className={classes.inscription}>
                    LUJO: Soil Resistivity Survey for CPS Groundbed Design at Shell Gbaran Phase-3A Enwhe Project 2021
                </p>

                <figure>
                    <img  className={classes.servicePic} src={Cathodic2} alt='men working'/>
                </figure>
                <p className={classes.inscription}>
                Lujo Technicians at work in one of our locations. 
                </p>

                <h2 className={classes.heading}>POTENTIAL SURVEY</h2>
                <p>
                    Lujo Integrated Services Ltd has competent crew equipped with the most current potential survey data 
                    gathering facilities. <br/>
                    For onshore and offshore pipelines, our engineers are efficient in analysis of survey data with the assistance of 
                    highly technological computer software for effective data analysis. <br/>
                    In potential survey of underwater installations LUJO deploys her experienced drivers with the required 
                    submersible equipment to achieve the set goal within the target time.
                </p>

                <h2 className={classes.heading}>CATHODIC PROTECTION OF UNDERWATER INSTALLATIONS</h2>
                <p>
                    Lujo Integrated Services Ltd has acquired great wealth of experience, human resources and equipment in the 
                    cathodic protection of corrosion installations. This involves workshop fabrications and underwater operations 
                    involving saturation drivers equipped with the required submersible equipment.

                </p>

                <h2 className={classes.heading}>CATHODIC PROTECTION DESIGN AND ENGINEERING</h2>
                <p>
                    LUJO INTEGRATED SERVICES LTD has competent cathodic protection engineers who conduct her engineering 
                    designs and where necessary invites her overseas associates. Our design are made specially to meet the 
                    specific needs of the clients and according to international standards. <br/>
                    Our partnership with HOCKWAY of UK has facilitated easy provision of high quality expertise to companies in 
                    the oil, Gas, Water, Petrochemical, Marine and other industrial firms.<br/>
                    These services are provided with total flexibility and dedication at all stages of any contract.<br/>
                    Complete package of specification for design, material selection, installation testing commissioning operations 
                    and maintenance can be provided at request by our client.
                </p>

                <figure>
                    <img  className={classes.servicePic} src={CathodicProtection} alt='lady working'/>
                </figure>
                <p className={classes.inscription}>
                    LUJO Laboratory Scale Testing Station for CPS Engineering Design.
                </p>

                <h2 className={classes.heading}>CORROSION MONITORING/SURVEILLANCE</h2>
                <p>
                    Lujo Integrated Services Ltd offers independent services in the area of internal corrosion monitoring and 
                    integrity checks of pipelines and vessels. Our clients could take advantage of this all important service to 
                    minimize compensation claims by local communities,resulting from sudden breakdown and leakage of their 
                    installations. This includes but not limited to: weight loss analysis, characterization of corrosion product; 
                    quantitative and qualitative analysis of fluids and corrosion products
                </p>

                <h2 className={classes.heading}>PIPELINE INSPECTION</h2>
                <p>
                    Lujo integrated service Ltd provides pipeline/flowlines inspecttion to determine the integrity of the line using
                    a range of pigs depending on the project goal. We also offer services in the areas of corrosion control,leak 
                    detection and repairs,integrity assessment, welding,sectional replacement of pipelines and pressure testing.
                </p>

                <h2 className={classes.heading}>NON- DESTRUCTIVE TESTING</h2>
                <p>
                    In addition to visual inspection,we provide the following major non-destructive testing (NDT) in the code of 
                    practice. 
                </p>
                <ol className={classes.serviceList}>
                    <li>Radiography testing</li>
                    <li>Magnetic Particle Testing</li>
                    <li>Ultrasonic Testing</li>
                    <li>Pentransts (dye & fluorescent) Testing</li>
                    <li>Corossion Mapping</li>
                </ol>

                <h2 className={classes.heading}>EARTHING AND LIGHTNING PROTECTION SYSTERM</h2>
                <p>
                    Lujo offers complete facility Earthing, surge and lightening protection services. The major services 
                    include the following:
                </p>
                <ul className={classes.serviceList}>
                    <li>Procurement of Earthing and Lightening protection system equipment</li>
                    <li>Design and engineering of new Earthing and Lightening protection system</li>
                    <li>Earthing and lightening protection system integrity check</li>
                    <li>Revamping and Retrofitting of existing Earthing and Lightening protection system</li>
                    <li>Re-engineering and upgrade of Earthing and Lightening protection system</li>
                </ul>
                <p>
                    <br/>
                    Lujo also offers other services such as
                </p>
                <ul className={classes.serviceList}>
                    <li>Expert evaluation and analysis of Facility Earthing and Lightening system</li>
                    <li>Fault finding/Troubleshooting</li>
                </ul>


                
                

            </main>


        <Footer/>
    </>
        
    )
}

export default ServicesPage;