import { useEffect } from 'react';
import Header from '../../components/Header/Header';
import Navigation from '../../components/Navigation/Navigation';
import MobileNav from '../../components/MobileNav/MobileNav';
import Label from '../../components/Label/Label';
import Footer from '../../components/Footer/Footer';


const GalleryPage = ()=>{
    useEffect(()=>{
        return ()=> window.scrollTo(0, 0)
    })
    return (
        <>
            <MobileNav/>
            <Navigation/>
            <Header type= 'galleryHeader'>  
            </Header>
            <Label tag='Gallery'/>
            <main>Gallery PAGE</main>

            <Footer/>
        </>
        
    )
}

export default GalleryPage;