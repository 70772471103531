import classes from './Label.module.css';

const Label = (props)=>{
    return (
        <div className={classes.Label}>
            <h1 className={classes.wh1}>{props.tag}</h1>

        </div>
    )
}

export default Label